<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>Chapel Schedule</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu v-model="termMenu">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text>
            {{ selectedTerm.title }}
            <v-icon right>fal fa-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="(item, index) in termOptions" :key="'term-' + index" @click="selectedTerm = item">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle v-if="item.remaining === 0">Required credits met</v-list-item-subtitle>
              <v-list-item-subtitle v-else>{{ item.remaining }} out of {{ item.required }} credits needed</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field :value="status.required" label="Required Credits" outlined readonly></v-text-field>
        </v-col>
        <v-col>
          <v-text-field :value="status.received" label="Received Credits" outlined readonly></v-text-field>
        </v-col>
        <v-col>
          <v-text-field :value="status.remaining" label="Remaining Credits" outlined readonly></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table :items="chapels" :headers="headers" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }">
      <template v-slot:item.date="{ item }">{{ stringFormatDate(item.date) }}</template>
      <template v-slot:item.credit="{ item }">
        <chapel-summary v-if="item.hasCredit === 'N'" :pidm="user.pidm" :chapel="item" :term="selectedTerm" :tooltip="item.type === 'Summary' ? 'Summary Rejected. Reason: ' + item.reason : ''" @submitted="load()"></chapel-summary>
        <v-tooltip top v-else>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>{{ item.hasCredit }}</v-btn>
          </template>
          <div>Credit Type: {{ item.type }}</div>
          <div v-if="item.reason">Description: {{ item.reason }}</div>
          <div v-if="item.creditValue">Credits: {{ item.creditValue }}</div>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { stringFormatDate } from '../../helpers/formatters'
import { ref, computed, watch, onMounted } from '@vue/composition-api'

export default {
  components: {
    ChapelSummary: () => import('@/components/chapel/summary')
  },
  setup (props, { root }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const service = root.$feathers.service('chapel/student')

    const termMenu = ref(false)
    const selectedTerm = ref('')
    const termOptions = ref([])
    const status = ref({
      required: 0,
      received: 0,
      remaining: 0
    })

    function load () {
      const { _id, term } = selectedTerm.value
      service.get(_id).then((creditData) => {
        status.value.required = creditData.required
        status.value.received = creditData.credits.reduce((acc, { credit }) => acc + credit, 0)
        status.value.remaining = status.value.required - status.value.received
        if (status.value.remaining < 0) status.value.remaining = 0
        let credits = {}
        creditData.credits.forEach(({ chapel, credit, type, reason }) => {
          credits[JSON.stringify(chapel)] = { credit, type, reason }
        })
        let termDesc = ''
        switch (term.substr(-2)) {
          case '05':
            termDesc = 'Spring '
            break
          case '60':
            termDesc = 'Fall '
            break
        }
        termDesc += term.substr(0, 4)
        root.$feathers.service('chapel/schedule').find({ query: { $or: [ { term }, { term: termDesc } ], $limit: 100, $sort: { date: 1 } } }).then(({ data }) => {
          chapels.value = []
          data.forEach(({ _id, date, speaker, title }) => {
            let id = JSON.stringify(_id)
            if (id in credits) {
              let temp = credits[id]
              if (temp.credit > 0) {
                chapels.value.push({ _id, date, speaker, title, hasCredit: 'Y', creditValue: temp.credit, type: temp.type, reason: temp.reason })
              } else if (temp.type === 'Summary' && temp.reason === 'Summary Submitted; Pending Review') {
                chapels.value.push({ _id, date, speaker, title, hasCredit: 'P', creditValue: temp.credit, type: temp.type, reason: temp.reason })
              } else {
                chapels.value.push({ _id, date, speaker, title, hasCredit: 'N', creditValue: temp.credit, type: temp.type, reason: temp.reason })
              }
            } else {
              chapels.value.push({ _id, date, speaker, title, hasCredit: 'N', creditValue: 0, type: 'No Credit Entry' })
            }
          })
        })
      })
    }

    watch(selectedTerm, ({ _id, term }) => {
      if (_id != null) {
        load()
      }
    })

    const chapels = ref([])

    onMounted(async () => {
      const aggregate = [
        { $match: { pidm: user.value.pidm } },
        { $unwind: { path: '$credits', preserveNullAndEmptyArrays: true } },
        { $group: {
          _id: {
            _id: '$_id',
            term: '$term',
            req: '$required'
          },
          received: {
            $sum: '$credits.credit'
          }
        } },
        { $project: {
          _id: '$_id._id',
          term: '$_id.term',
          required: '$_id.req',
          received: '$received',
          remaining: { $max: [0, { $subtract: ['$_id.req', '$received'] }] }
        } },
        { $sort: { term: -1 } }
      ]
      const { data: aggData } = await service.find({ query: { aggregate } })
      console.log(aggData)
      aggData.forEach(({ _id, term, required, received, remaining }) => {
        let year = term.substr(0, 4)
        let suffix = term.substr(-2)
        let desc = ''
        switch (suffix) {
          case '05':
            desc = 'Spring'
            break
          case '60':
            desc = 'Fall'
            break
          case '02':
            desc = 'Jan Term'
            break
          case '10':
            desc = 'May Term'
            break
        }
        let title = desc + ' ' + year
        termOptions.value.push({ _id, term, title, required, received, remaining })
        if (selectedTerm.value === '') selectedTerm.value = termOptions.value[0]
      })
    })

    const headers = ref([
      { text: 'Date', value: 'date' },
      { text: 'Speaker', value: 'speaker' },
      { text: 'Title', value: 'title' },
      { text: 'Credit', value: 'credit' }
    ])

    return {
      user,
      termMenu,
      selectedTerm,
      termOptions,
      status,
      chapels,
      headers,
      load,
      stringFormatDate
    }
  }
}
</script>
