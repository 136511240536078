var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Chapel Schedule")]),_c('v-spacer'),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" "+_vm._s(_vm.selectedTerm.title)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fal fa-chevron-down")])],1)]}}]),model:{value:(_vm.termMenu),callback:function ($$v) {_vm.termMenu=$$v},expression:"termMenu"}},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.termOptions),function(item,index){return _c('v-list-item',{key:'term-' + index,on:{"click":function($event){_vm.selectedTerm = item}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))]),(item.remaining === 0)?_c('v-list-item-subtitle',[_vm._v("Required credits met")]):_c('v-list-item-subtitle',[_vm._v(_vm._s(item.remaining)+" out of "+_vm._s(item.required)+" credits needed")])],1)],1)}),1)],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.status.required,"label":"Required Credits","outlined":"","readonly":""}})],1),_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.status.received,"label":"Received Credits","outlined":"","readonly":""}})],1),_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.status.remaining,"label":"Remaining Credits","outlined":"","readonly":""}})],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.chapels,"headers":_vm.headers,"footer-props":{ 'items-per-page-options': [5, 10, 15, 20] }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.stringFormatDate(item.date)))]}},{key:"item.credit",fn:function(ref){
var item = ref.item;
return [(item.hasCredit === 'N')?_c('chapel-summary',{attrs:{"pidm":_vm.user.pidm,"chapel":item,"term":_vm.selectedTerm,"tooltip":item.type === 'Summary' ? 'Summary Rejected. Reason: ' + item.reason : ''},on:{"submitted":function($event){return _vm.load()}}}):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(_vm._s(item.hasCredit))])]}}],null,true)},[_c('div',[_vm._v("Credit Type: "+_vm._s(item.type))]),(item.reason)?_c('div',[_vm._v("Description: "+_vm._s(item.reason))]):_vm._e(),(item.creditValue)?_c('div',[_vm._v("Credits: "+_vm._s(item.creditValue))]):_vm._e()])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }